.ant-btn {
  line-height: 1;
}

.ant-divider-horizontal {
  margin: 0 0 24px;
}

.ant-picker {
  width: 100%;
}

.ant-input,
.ant-picker,
.ant-select.ant-select-in-form-item {
  height: 40px;
}

.ant-input-affix-wrapper {
  padding: 0 11px;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-inline-start: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-table-wrapper .ant-table-thead > tr > th {
  // font-weight: 500;
  color: #1a3353;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ant-input[disabled] {
  color: black;
}
.ant-select-selection-item {
  color: black;
}
.ant-pagination-next,
.ant-pagination-prev {
  border: 1px solid lightgray;
}
.ant-pagination .ant-pagination-options {
  display: none;
}
.ant-layout .ant-layout-sider {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}
.iconWarning > div > div {
  border: none;
}
.iconWarning > div > div > svg {
  margin-bottom: 15px;
}
.iconFilter > div {
  width: 40px;
  height: 40px;
  margin: 0 0 0 10px;
  border-radius: 50%;
}
.iconFilterQues > div {
  width: 40px;
  height: 40px;
  margin: 0 0 10px 10px;
  border-radius: 50%;
}
.iconCopy > div > div {
  border: none;
  background: none;
}
.ant-image {
  height: 100px;
  width: 100px;
}
.ant-row {
  margin-right: 0 !important;
}
.textA2 > .ant-input {
  border-color: #ffffff;
  padding: 0px 20px;
}
.textA2 > .ant-input:focus-within {
  border-color: #ffffff;
  box-shadow: none;
}
.textA2 > .ant-input:focus {
  border-color: #ffffff;
  box-shadow: none;
}
.FormCharacter > .ant-row > div > div {
  margin-left: 28px;
  margin-right: -13px;
}
.TableAbility > .ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #fec9c9;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}
.TableAbility > .ant-table-wrapper table {
  border: 1px solid #e6ebf1;
  border-radius: 8px;
}
[data-rich-colors='true'] [data-sonner-toast][data-type='error'],
[data-rich-colors='true']
  [data-sonner-toast][data-type='error']
  [data-close-button] {
  padding: 10px;
}
[data-sonner-toast][data-type='success'] {
  padding: 10px;
}
.previewImageDetail > .ant-image {
  height: 100%;
}
.previewImageDetail > .ant-image > img {
  width: 77%;
  height: auto;
  vertical-align: middle;
}
.showImageInput > div > div {
  width: 70px;
  height: 40px;
}
