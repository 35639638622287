.title {
    margin-top: 50px;
    padding: 300px 0;
    text-align: center;
    font-size: 64px;
  
    @media (max-width: 768px) {
      font-size: 24px;
      padding: 15px;
    }
  }
  